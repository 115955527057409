.App {
  display: flex;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0px;

  .editor {
    flex: 1;
    position: relative;

    .text-editor {
      background: #eee;
      border: none;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .diagram {
    flex: 1;
    padding: 16px;
  }
}
