.text-editor {
  position: relative;

  .text-editor-textarea {
    background: #eee;
    border: none;
    position: absolute;
    width: 100%;
    height: 100%;
    resize: none;
    color: transparent;
    padding: 4px;
    border-radius: 0px;
    font-size: 14px;
    caret-color: #333;

    &:focus,
    &:active {
      border: none;
      outline: 0px;
    }

    &::selection {
      color: transparent;
      background: #ccc;
    }
  }

  .text-editor-formatted {
    padding: 4px;
    font-size: 14px;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-y: auto;
    color: #333;

    .source,
    .dest {
      color: blue;
    }

    .message {
      color: #000;
    }
  }
}
